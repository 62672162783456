<template>
  <div class="mt30" v-if="data.length > 0" id="box">
    <waterfall :col="4" :data="data" @loadmore="loadmore" :gutterWidth="0">
      <template>
        <div class="cell-item" v-for="(item, index) in data" :key="index">
          <!-- <div class="collectIcon">
            <p class="mr20 f14 c_33">收藏</p>
            <i class="iconfont">&#xe7b3;</i>
          </div> -->
          <img v-if="item.img" :src="item.img" alt="加载错误" />
          <div class="dow"  @click="goLink(item.pid)"><i class="iconfont">&#xe74d;</i></div>
					<div class="txt-over">{{item.title}}</div>
        </div>
      </template>
    </waterfall>
    <!-- <p
      class="t_c mt20 loadImg"
      :class="{ load: isLoading }"
      v-if="data.length > 0"
    >
      <img src="@/assets/image/loading.gif" alt="" />
    </p> -->
  </div>
  <div v-else>
    <el-empty description="没有内容哦"></el-empty>
  </div>
</template>
<script>
export default {
  name: "DownloadChartlet",
  props: ["content"],
  data() {
    return {
      data: [],
      isLoading: false,
    };
  },
  computed: {},
  methods: {
    goLink(id) {
      const {href} = this.$router.resolve({ path: "/chartlet/detail", query: { detailId: id } });
      window.open(href, '_blank')
    },
    loadmore() {
      this.$emit("nextPage");
    },
    pushData() {
      if (this.content.activeName == 2205) {
        this.data = this.data.concat(this.content.data);
        this.isLoading = false;
      }
    },
    handleScroll() {
      const scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      const windowHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
      const offsetTop = document.querySelector("#box").offsetTop;
      if (scrollTop + windowHeight >= offsetTop && !this.isLoading) {
        //到了这个就可以进行业务逻辑加载后台数据了
        this.isLoading = true;
        this.loadmore();
      }
    },
  },
  mounted() {
    this.pushData();
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  watch: {
    content() {
      if (this.data.length < this.content.total && this.content.activeName == 2205) this.pushData();
    },
  },
};
</script>
<style scoped>
.collectIcon {
  position: absolute;
  top: 0.1rem;
  left: 0.2rem;
  visibility: hidden;
  opacity: 0;
  transition: 0.5s;
  padding: 0.07rem 0.1rem;
  background: #ffffff;
  border-radius: 0.04rem;
  display: flex;
  align-items: center;
  z-index: 1;
}
* {
  margin: 0;
}
.cell-item {
  position: relative;
  width: 2.4rem;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-bottom: 0.2rem;
}
.item-title {
	height: 0.18rem;
	line-height: 0.18rem;
	font-size: 0.12rem;
	font-weight: 500;
	color: rgba(102, 102, 102, 1);
	text-align: left;
}
.dow {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  bottom: 0.28rem;
  right: 0.1rem;
  font-size: 0.22rem;
  padding: 0.07rem 0.15rem;
  background: #fcfcfc;
  border-radius: 0.04rem;
  transition: 0.5s;
}
.cell-item:hover .collectIcon {
  visibility: visible;
  opacity: 1;
}
.cell-item:hover .dow {
  visibility: visible;
  opacity: 1;
}
.cell-item:hover {
  transform: translateY(-6px);
  box-shadow: none;
}
.vue-waterfall {
  width: 101%;
}
.cell-item img {
  width: 100%;
}
.vue-waterfall::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.vue-waterfall::-webkit-scrollbar-thumb {
  background-color: #ddd;
  border-radius: 3px;
}
</style>