<template>
  <div class="t_c tableList">
    <el-table
      v-if="content.total > 0"
      class="mt30"
      :data="content.data"
      style="width: 100%"
      :header-cell-style="{ background: '#F9F9FF' }"
    >
      <el-table-column fixed label="名称" width="500">
        <template slot-scope="scope">
          <div class="df_Yc">
            <div class="downloadImg">
              <el-image :src="scope.row.img || scope.row.cover"></el-image>
            </div>
            <p class="downloadName">{{ scope.row.title }}</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="addTime" label="下载时间">
        <template slot-scope="scope">
          <div>
            {{ scope.row.addTime | formartDate }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="province" label="编辑">
        <template slot-scope="scope">
          <div class="listSet">
            <div class="c_p download" @click="download(scope.row)">下载</div>
            <div>/</div>
            <div class="c_p delete" @click="deleteFun(scope)">删除</div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div v-else>
      <el-empty description="没有内容哦"></el-empty>
    </div>
    <el-pagination
      background
      style="margin-top: 0.2rem"
      v-if="content.total > 0"
      layout="prev, pager, next"
      :total="content.total"
      @current-change="setPage"
      :page-size="parseInt(content.per_page)"
    ></el-pagination>
  </div>
</template>
<script>
import { getToken } from "@/utils/auth";
import { deleteDownload } from "@/Api";
export default {
  name: "DownloadSu",
  props: ["content"],
  data() {
    return {};
  },
  methods: {
    //分页
    setPage(val) {
      this.$emit("setPageFun", val);
    },
    //删除
    deleteFun(data) {
      const index = data.$index;
      const params = {
        token: getToken(),
        idStr: data.row.id,
      };
      deleteDownload(params).then((res) => {
        if (res.data.message === "delSuccess") {
          this.content.data.splice(index, 1);
          this.$message.success("删除成功");
        } else {
          this.$message.error("删除失败");
        }
      });
    },
    //  下载
    download(item) {
      const fileUrl =
        "https://api.zhishew.com/v2/user/Download/doDown?id=" +
        item.pid +
        "&token=" +
        getToken();
      window.open(fileUrl, "_blank");
    },
  },
};
</script>
<style scoped>
.nullData {
  width: 5rem;
}
.download:hover {
  color: #00a2d4;
}

.delete:hover {
  color: red;
}

.listSet {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #666666;
  font-size: 0.16rem;
  width: 1rem;
}

.downloadImg {
  width: 1.2rem;
  height: 0.9rem;
  border-radius: 0.04rem;
  overflow: hidden;
  margin-right: 0.2rem;
}

.downloadImg img {
  width: 100%;
}

.tableList >>> .el-table__body-wrapper::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.tableList >>> .el-table__body-wrapper::-webkit-scrollbar-thumb {
  background-color: #ddd;
  border-radius: 3px;
}
</style>