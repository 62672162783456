<template>
  <div class="myDownBox">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="SU模型" name="1" lazy></el-tab-pane>
      <el-tab-pane label="3D模型" name="1393" lazy></el-tab-pane>
      <el-tab-pane label="PS素材" name="4" lazy> </el-tab-pane>
      <el-tab-pane label="方案文本" name="1394" lazy></el-tab-pane>
      <el-tab-pane label="资料库" name="2" lazy></el-tab-pane>
      <el-tab-pane label="图库" name="3" lazy> </el-tab-pane>
      <el-tab-pane label="贴图" name="2205" lazy> </el-tab-pane>
    </el-tabs>
    <div class="df_Yc mt10" v-if="activeName != '3' && activeName != '2205'">
      <el-date-picker
        style="margin-right: 0.2rem"
        v-model="getParams.dateTime"
        type="daterange"
        align="right"
        unlink-panels
        range-separator="-"
        start-placeholder="开始时间"
        end-placeholder="结束时间"
        :picker-options="pickerOptions"
      >
      </el-date-picker>
      <!-- <el-input
        style="width: 180px; margin: 0 0.2rem"
        placeholder="请输入内容"
        v-model="getParams.key"
        clearable
        @clear="searchFun"
        @keyup.enter.native="searchFun"
      >
      </el-input> -->
      <div class="searchActive" @click="searchFun()">查询</div>
    </div>
    <DownloadSu
      @setPageFun="setPageFun"
      :content="downloadListObj"
      v-show="activeName == '1'"
    ></DownloadSu>
    <DownloadMax
        @setPageFun="setPageFun"
        :content="downloadListObj"
        v-show="activeName == '1393'"
    ></DownloadMax>
    <DownloadSchemeText
        @setPageFun="setPageFun"
        :content="downloadListObj"
        v-show="activeName == '1394'"
    ></DownloadSchemeText>
    <DownloadMaterial
      v-show="activeName == '2'"
      @setPageFun="setPageFun"
      :content="downloadListObj"
    ></DownloadMaterial>
    <DownloadPhoto
      :content="downloadListObj"
      v-if="activeName == '3'"
      @nextPage="nextPage"
    ></DownloadPhoto>
    <DownloadChartlet
      :content="downloadListObj"
      v-if="activeName == '2205'"
      @nextPage="nextPage"
    ></DownloadChartlet>
    <DownloadSu
      :content="downloadListObj"
      v-if="activeName == '4'"
      @nextPage="nextPage"
    ></DownloadSu>
  </div>
</template>
<script>
import DownloadSu from "./componts/DownloadSu.vue";
import DownloadMaterial from "./componts/DownloadMaterial.vue";
import DownloadPhoto from "./componts/DownloadPhoto.vue";
import DownloadChartlet from "./componts/DownloadChartlet.vue";
import DownloadMax from "./componts/DownloadMax"
import DownloadSchemeText from "./componts/DownloadSchemeText";
import { getMyDownload } from "../../Api";
import { getToken } from "../../utils/auth";

export default {
  name: "MyDownload",
  data() {
    return {
      activeName: "1",
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      getParams: {
        dateTime: "",
        key: "",
        page: 1,
        token: getToken(),
        cate: 561,
        per_page: 6,
      },
      downloadListObj: {
        data: [],
        total: 0,
        activeName: null,
        per_page: null,
      },
    };
  },
  components: {
    DownloadSu,
    DownloadMaterial,
    DownloadPhoto,
		DownloadChartlet,
    DownloadMax,
    DownloadSchemeText
  },
  created() {
    this.getMyDownload();
  },
  methods: {
    // 获取我的下载
    getMyDownload() {
      if (this.getParams.dateTime && this.getParams.dateTime.length > 0) {
        this.getParams.start_time =
          Date.parse(this.getParams.dateTime[0]) / 1000;
        this.getParams.end_time = Date.parse(this.getParams.dateTime[1]) / 1000;
      } else {
        this.getParams.start_time = "";
        this.getParams.end_time = "";
      }
      getMyDownload(this.getParams).then((res) => {
        const data = res.data;
        data.activeName = this.activeName;
        this.downloadListObj = data;
      });
    },
    //滚动
    nextPage() {
      this.getParams.page += 1;
      this.getMyDownload();
    },
    //搜素
    searchFun() {
      this.getParams.page = 1;
      this.getMyDownload();
    },
    //分页
    setPageFun(page) {
      this.getParams.page = page;
      this.getMyDownload();
    },
    handleClick() {
      // this.getParams = this.$options.data().getParams;
      if (this.activeName == 1) {
        this.getParams.cate = 561;
        this.getParams.per_page = 6
      }
      if (this.activeName == 2) {
        this.getParams.cate = 7;
        this.getParams.per_page = 6
      }
      if (this.activeName == 3) {
				this.getParams.fid = 2381;
        this.getParams.cate = 2382;
        this.getParams.per_page = 16
      }
      if (this.activeName == 4) {
        this.getParams.cate = 1196;
        this.getParams.per_page = 6
      }
      if (this.activeName == 1393) {
        this.getParams.cate = 1393;
        this.getParams.per_page = 6
      }
      if (this.activeName == 1394) {
        this.getParams.cate = 1394;
        this.getParams.per_page = 6
      }
			
			if (this.activeName == 2205) {
				this.getParams.fid = 2205;
				this.getParams.cate = 2205;
				this.getParams.per_page = 16
			} else if (this.activeName == 3) {
				this.getParams.fid = 2381;
        this.getParams.cate = 2381;
        this.getParams.per_page = 16
      }else {
				this.getParams.fid = "";
			}
      this.getParams.page = 1;
      this.getMyDownload();
    },
  },
};
</script>
<style scoped>
.searchActive {
  width: 68px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  cursor: pointer;
  background: #f46600;
  border-radius: 0.04rem;
  color: #ffffff;
  font-size: 0.14rem;
}
.myDownBox {
  background: #ffffff;
  padding: 0.3rem;
  border-radius: 0.04rem;
}
.myDownBox >>> .el-date-editor .el-range__close-icon {
  line-height: 27px;
}
.myDownBox >>> .el-date-editor .el-range-separator {
  line-height: 27px;
}
.myDownBox >>> .el-date-editor .el-range__icon {
  line-height: 27px;
}
.myDownBox >>> .el-range-editor.el-input__inner {
  width: 230px;
  line-height: 32px;
  height: 32px;
}
.myDownBox >>> .el-input__inner {
  line-height: 32px;
  height: 32px;
}
.myDownBox >>> .el-input__icon {
  line-height: 32px;
}
.myDownBox >>> .el-tabs__item.is-active {
  color: #f46600;
}
.myDownBox >>> .el-tabs__active-bar {
  background-color: #f46600;
}
.myDownBox >>> .el-tabs__item:hover {
  color: #f46600;
}
.myDownBox >>> .el-range-editor .el-range-input {
  font-size: 0.14rem;
}
</style>